import React, {useEffect, useState} from 'react'
import {CustomInput} from '../../components/ui';
import {collection, doc, setDoc} from "@firebase/firestore";
import {db} from "../../firebase-config";

const initialValue: any = {
    accessCode: '',
    fullName: '',
    phone: '',
    address: '',
    email: '',
    carYear: '',
    carBrand: '',
    imei: '',
}

export default function NewCar(props: any) {
    const [formData, setFormData] = useState(initialValue);
    const [loading, setLoading] = useState(false);

    //driver model
    const driverModel = collection(db, "drivers")

    const formStyle = `lg:h-fit h-[80vh] overflow-y-auto px-7 pb-[3rem] pt-[2rem] grid gap-y-2`;
    const h1Style = 'text-[20px] font-[600] leading-[42px]';
    const labelStyle = 'text-[13px] font-[400]';
    const btnStyle = 'w-full mt-4 rounded-[7px] text-[14px] text-center bg-ct-primary font-[400] text-white border px-[15px] py-[12px] outline-none active:outline-none';
    const btnInverseStyle = 'w-full mt-4 rounded-[7px] text-[14px] text-center border border-ct-primary font-[400] text-ct-primary px-[15px] py-[12px] outline-none active:outline-none';

    const handleChange = (e: any) => {
        const {name, value} = e.target;
        setFormData((prevData: any) => ({
            ...prevData,
            [name]: value
        }));
    };

    //side-effect
    useEffect(() => {
        setFormData({...formData, accessCode: "TRK" + Math.floor((Math.random() * 100) * 99999)})
    }, []);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        try {
            let isOkay = false
            const q = Object.keys(formData);
            for(let i =0; i<q.length; i++){
                if (!formData[q[i]]) {
                    alert(`Incomplete form @${q[i]}, try again`)
                    isOkay = false
                    break
                }else {
                    isOkay = true
                }
            }
            //processing
            setLoading(true)
            //let go in db
            if(isOkay){
               //insert into db
                await setDoc(doc(driverModel), formData)
                alert("New driver added !")
                props.closeModal();
            }
            setLoading(true)
            //props.closeModal();
        } catch (error) {
            console.error('Error submitting form data:', error);
            // @ts-ignore
            alert(error.message);
        }
    };

    return (
        <form className={formStyle}>
            <h1 className={h1Style}>New Car / Driver</h1>

            <div className="grid lg:grid-cols-2 grid-cols-1 gap-x-3">
                <div>
                    <label className={labelStyle}>Full name</label>
                    <CustomInput
                        type='text'
                        required={true}
                        placeholder='John Doe'
                        name='fullName'
                        value={formData.fullName}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <label className={labelStyle}>Email Address</label>
                    <CustomInput
                        type='email'
                        required={true}
                        placeholder='xxxxx@gmail.com'
                        name='email'
                        value={formData.email}
                        onChange={handleChange}
                    />
                </div>
            </div>


            <div className="grid lg:grid-cols-2 grid-cols-1 gap-x-3">
                <div>
                    <label className={labelStyle} htmlFor="">Car Year</label>
                    <CustomInput
                        type='text'
                        required={true}
                        placeholder='2020'
                        name='carYear'
                        value={formData.carYear}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <label className={labelStyle} htmlFor="">Car Brand</label>
                    <CustomInput
                        type='text'
                        required={true}
                        placeholder='GLK'
                        name='carBrand'
                        value={formData.carBrand}
                        onChange={handleChange}
                    />
                </div>
            </div>

            <div className="grid lg:grid-cols-2 grid-cols-1 gap-x-3">
                <div>
                    <label className={labelStyle} htmlFor="">Home address</label>
                    <CustomInput
                        type='text'
                        required={true}
                        placeholder='580 Whiff Oaf Lane'
                        name='address'
                        value={formData.address}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <label className={labelStyle} htmlFor="">Contact/Phone</label>
                    <CustomInput
                        type='text'
                        required={true}
                        placeholder='+234 810 1122 334'
                        name='phone'
                        value={formData.phone}
                        onChange={handleChange}
                    />
                </div>
            </div>

            <div className="grid lg:grid-cols-2 grid-cols-1 gap-x-3">
                <div>
                    <label className={labelStyle} htmlFor="">IMEI No.</label>
                    <CustomInput
                        type='text'
                        required={true}
                        placeholder='1234567899'
                        name='imei'
                        value={formData.imei}
                        onChange={handleChange}
                    />
                </div>

                <div>
                    <label className={labelStyle} htmlFor="">Access Code: <span onClick={() => {
                        //copy code
                        navigator.clipboard.writeText(formData.accessCode).then(r => alert("Tracking code copied"))
                    }} style={{cursor: 'pointer', color: 'blue'}}>Copy</span> </label>
                    <CustomInput
                        disabled={true}
                        type='text'
                        required={true}
                        placeholder='123456ABCA11'
                        name='accessCode'
                        value={formData.accessCode}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="grid grid-cols-3 gap-x-3">
                <div className='col-span-1'>
                    <button onClick={props.closeModal} className={btnInverseStyle}>Cancel</button>
                </div>
                <div className='col-span-2'>
                    <button disabled={loading} onClick={handleSubmit} className={btnStyle}>{loading ? 'Processing...' : 'Add Car'}</button>
                </div>
            </div>
        </form>
    )
}
