import React from 'react'
import Nav from './Nav'
import Jumbotron from './Jumbotron'
import FeatureSection from './FeatureSection'
import AboutSection from './AboutSection'
import Contact from './Contact'
import Footer from './Footer'

type Props = {}

export default function LandingPage({}: Props) {
    return (
        <div>
            <Nav />
            <Jumbotron />
            <FeatureSection />
            <AboutSection />
            <Contact /> 
            <Footer />
        </div>
    )
}