import React from 'react'
import {AppLayout} from '../../components/layouts'
import Map from './Map'
import Dashboard from './Dashboard';

export default function Home() {
    return (
        <AppLayout>
            <Dashboard/>
        </AppLayout>
    )
}
