import React, {useEffect, useState} from 'react'
import searchIcon from '../../assets/icons/search.svg';
import carIcon from '../../assets/images/car.png';
import {CustomModal} from '../../components/ui';
import {and, collection, doc, getDocs, query, where, getDoc, onSnapshot} from "@firebase/firestore";
import {db} from "../../firebase-config";
// @ts-ignore
import GoogleMapReact from 'google-map-react';

// @ts-ignore
export default function Map({focusData}) {
    const [cutFuelModal, setCutFuelModal] = useState(false);
    const [offIgnitionModal, setOffIgnitionModal] = useState(false);
    const [centerLockModal, setCenterLockModal] = useState(false);

    const [dashTracker, setDashTracker] = useState([])
    const [dashTrackerOne, setDashTrackerOne] = useState({data: {lon: '', lat: ''}})
    const [drivers, setDrivers] = useState([]);

    const headerStyle = `text-[19px] leading-[46px] tracking-[0.7px] text-ct-primary font-[600]`;
    const actionHeaderStyle = `text-[18px] leading-[46px] tracking-[0.7px] text-ct-primary font-[600]`;
    const buttonStyle = `text-center h-fit w-[123px] py-2 text-white text-[12px] font-[600] tracking-[0.7px]`;
    const actionParStyle = `text-[12px] leading-[40px] tracking-[0.7px] text-[#282828] font-[400]`;
    const modalBtnContainer = `flex justify-center gap-x-3 mt-4`;
    const modalBtnStyle = 'text-center py-2.5 px-7 text-white text-[12px] font-[600] tracking-[0.7px] rounded-[7px]';
    const modalStyle = 'lg:w-[30%] md:w-[50%] w-[75%] bg-white mx-auto lg:mt-[20%] mt-[50%] rounded-[7px] p-[20px]';

    //firestore model
    const trackerModel = collection(db, "devices")
    //driver model
    const driverModel = collection(db, "drivers")


    const defaultMaps = {
        center: {
            lat: 6.5295288,
            lng: 3.281128
        },
        zoom: 12
    };

    const createMapOptions = (maps)=> {
        return {
            panControl: true,
            mapTypeControl: true,
            scrollwheel: true,
            zoomControl: true,
        }
    }


    const handleIgnitionOff = () => {
        //TODO
        alert("Use TRK on mobile app to complete this action")
        setOffIgnitionModal(false)
    }
    const handleFuelCut = () => {
        //TODO
        alert("Use TRK on mobile app to complete this action")
        setCutFuelModal(false)

    }
    const handleCentralLock = () => {
    }

    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = async () => {
        try {

        } catch (error) {
            console.error('Error searching:', error);
        }
    };

    const handleChange = (e: any) => {
        setSearchTerm(e.target.value);
    };

    const handleKeyPress = (e: any) => {
        if (e.key === 'Enter') {
            handleSearch().then(null)
        }
    };

    //search only 1 car
    const fetchTrackersOne = async () => {
        const dataTrackerSearch = doc(trackerModel, focusData.imei)
        // @ts-ignore
        const data = await getDoc(dataTrackerSearch)
        // @ts-ignore
        setDashTrackerOne(data.data())
    }

    //use effect
    useEffect(() => {
        if (!focusData) {
            fetchTrackers().then(null)
        }else{
            fetchTrackersOne().then(null)
        }
    }, [focusData]);


    //snapShots
    const snapShotData = async () => {
        if (!focusData) {
            fetchTrackers().then(null)
        }
    }

    const fetchTrackers = async () => {
        const dataTracker = await getDocs(trackerModel)
        // @ts-ignore
        setDashTracker(dataTracker.docs.reverse())
        const data = await getDocs(driverModel)
        // @ts-ignore
        setDrivers(data.docs.reverse())
        //start listener
        // @ts-ignore
        const unsub = onSnapshot(doc(trackerModel), (doc) => {
            console.log("Changes !")
        });
    }
//"358657104184457"
    const CMarker = ({data, lat, lng, name}) => {
        return (<div style={{width: 100}}>
            <div style={{background: "white", width: 100, padding: 5}}>
                <strong>{name||"Loading Name"}</strong><br/>
                {data.speed+""+data.speedUnit}<br/>
            </div>
            <img src={carIcon} style={{width: 38, height: 38}} alt={""}/>
        </div>)
    }

    return (
        <div className='w-full h-full'>
            {/* map canvas  */}
            <div className="w-full h-full">

                {/* map header  */}
                <div
                    className='flex justify-between items-center px-[20px] py-[10px] border-b-2 border-b-[#C2C2C2] bg-white  rounded-t-[7px]'>
                    <h1 className={`${headerStyle}`}>Map</h1>
                    <div className='flex w-[39%] items-center bg-ct-primaryLess py-2 pr-2 rounded-[50px]'>
                        <input
                            type="search"
                            className='w-full bg-transparent text-[11.5px] outline-none active:outline-none border-0 pl-3.5'
                            placeholder='Search vehicle, customer, and others'
                            value={searchTerm}
                            onChange={handleChange}
                            onKeyPress={handleKeyPress}
                        />
                        <img src={searchIcon} alt="search" className='hover:cursor-pointer px-1.5'/>
                    </div>
                </div>

                {/* map body  */}
                <div className='bg-gray-50 h-[80%]'>
                    {dashTracker.length > 0 && drivers.length>0 && !focusData &&
                    <GoogleMapReact
                        options={createMapOptions}
                        yesIWantToUseGoogleMapApiInternals
                        bootstrapURLKeys={{key: "AIzaSyD20fkoKAerD3TgXArPL_pBuI_RoeVjfs4", language: 'en'}}
                        defaultCenter={defaultMaps.center}
                        defaultZoom={defaultMaps.zoom} onClick={()=>{
                            //alert("Hello")
                    }}>

                        {dashTracker.map((dModelRef, index) => {
                            // @ts-ignore
                            const dataRef = dModelRef.data()
                            // @ts-ignore
                            let name = drivers.find(x=>x.data().imei===dataRef.data.imei.toString()).data().fullName
                            return (
                                <CMarker key={index} lat={dataRef?.data?.lat} lng={dataRef?.data?.lon} data={dataRef.data} name={name}/>
                            )
                        })}

                    </GoogleMapReact>
                    }
                    {dashTrackerOne.data.lon!=='' && focusData &&
                        <GoogleMapReact
                            yesIWantToUseGoogleMapApiInternals
                            bootstrapURLKeys={{key: "AIzaSyD20fkoKAerD3TgXArPL_pBuI_RoeVjfs4", language: 'en'}}
                            defaultCenter={defaultMaps.center}
                            defaultZoom={defaultMaps.zoom}>
                                    <CMarker lat={dashTrackerOne?.data.lat} lng={dashTrackerOne?.data.lon} data={dashTrackerOne?.data} name={"Target Driver"}/>
                        </GoogleMapReact>
                    }
                </div>
            </div>

            {/* actions */}
            <div className='w-full bg-white -mt-[3.7rem] rounded-[7px]'>
                <div
                    className='w-full flex justify-between items-center px-[20px] py-[20px] border-b border-b-[#C2C2C2]'>
                    <div>
                        <h2 className={`${actionHeaderStyle}`}>Actions</h2>
                        <p className='text-[#282828] font-[600] tracking-[0.7px] text-[12px]'>Quick
                            Controller: <strong>{focusData?.imei || "No Data"}</strong></p>
                    </div>
                    <div className='flex gap-x-4'>
                        <button className={`${buttonStyle} bg-[#6418C3]`}
                                onClick={() => focusData ? setCutFuelModal(true) : alert("Track a driver to use this function")}>Cut
                            Fuel
                        </button>
                        <button className={`${buttonStyle} bg-[#FA7976]`}
                                onClick={() => focusData ? setOffIgnitionModal(true) : alert("Track a driver to use this function")}>Off
                            Ignition
                        </button>
                    </div>
                </div>
                <div className='w-full flex'>
                    <div className='w-[25%] px-[15px] border-r border-r-[#C2C2C2]'>
                        <p className={`${actionParStyle} pt-2.5`}>Driver</p>
                        <p className={`${actionParStyle}`}>Device ID</p>
                        <p className={`${actionParStyle} pb-2.5`}>Car Model</p>
                    </div>
                    <div className='w-[75%] px-[10px]'>
                        <p className={`${actionParStyle} pt-2.5`}>{focusData?.fullName || "No Data"} [{focusData?.accessCode || "No Data"}]</p>
                        <p className={`${actionParStyle}`}>{focusData?.imei || "No Data"}</p>
                        <p className={`${actionParStyle} pb-2.5 font-black`}>{(focusData?.carBrand) || "No Brand"}/{(focusData?.carYear) || "No Brand"}</p>
                    </div>
                </div>
            </div>


            {cutFuelModal &&
                <CustomModal
                    closeModal={() => setCutFuelModal(false)}
                    modalStyle={modalStyle}
                >
                    <h1 className='text-center text-[14px]'>Are you sure you want to cut off fuel supply?</h1>
                    <div className={modalBtnContainer}>
                        <button
                            className={`${modalBtnStyle} bg-ct-green`}
                            onClick={handleFuelCut}>
                            Yes
                        </button>
                        <button
                            className={`${modalBtnStyle} bg-red-500`}
                            onClick={() => setCutFuelModal(false)}>
                            Cancel
                        </button>
                    </div>
                </CustomModal>
            }

            {offIgnitionModal &&
                <CustomModal
                    closeModal={() => setOffIgnitionModal(false)}
                    modalStyle={modalStyle}>
                    <h1 className='text-center text-[14px]'>Are you sure you want to turn off Ignition?</h1>
                    <div className={modalBtnContainer}>
                        <button
                            className={`${modalBtnStyle} bg-ct-green`}
                            onClick={handleIgnitionOff}>
                            Yes
                        </button>
                        <button
                            className={`${modalBtnStyle} bg-red-500`}
                            onClick={() => setOffIgnitionModal(false)}>
                            Cancel
                        </button>
                    </div>
                </CustomModal>
            }

            {centerLockModal &&
                <CustomModal
                    closeModal={() => setCenterLockModal(false)}
                    modalStyle={modalStyle}
                >
                    <h1 className='text-center text-[14px]'>Are you sure you want to initiate central lock?</h1>
                    <div className={modalBtnContainer}>
                        <button
                            className={`${modalBtnStyle} bg-ct-green`}
                            onClick={handleCentralLock}>
                            Yes
                        </button>
                        <button
                            className={`${modalBtnStyle} bg-red-500`}
                            onClick={() => setCenterLockModal(false)}>
                            Cancel
                        </button>
                    </div>
                </CustomModal>
            }
        </div>
    )
}
