import React from 'react';
import emptyImg from '../../assets/images/empty-box.png';


interface Props {
    text: string;
    style?: string
}

export default function Empty({ text, style  }: Props) {
  return (
    <div className={`w-fit mx-auto ${style}`}>
        <img src={emptyImg} alt="empty" className='lg:h-[50%] lg:w-[50%] mx-auto' />
        <p className='text-center font-[600] text-[16px] capitalize mt-3'>{text}</p>
    </div>
  )
}
