import React, { useState } from 'react';
import downIcon from '../../assets/icons/downArrow.svg';
import upIcon from '../../assets/icons/upArrow.svg';

type Props = {
    selectDisplayStyle?: string
}

export default function DaysSelect({ selectDisplayStyle }: Props) {
  const [isToggle, setIsToggle] = useState(false);
  const [selectedDay, setSelectedDay] = useState('past 7 days');

  const actionParStyle = `text-[12px] tracking-[0.7px] text-[#282828] font-[400]`;
  const dropdownStyle = `absolute z-[99] -mt-1 shadow rounded-b-[7px] w-full bg-white`;
  const liStyle = `py-1.5 hover:cursor-pointer hover:bg-gray-50 px-[20px]`;

  return (
    <div className='relative w-full'>
        <div className={`w-full flex items-center bg-white justify-between ${selectDisplayStyle}`}>
            <p className={`capitalize ${actionParStyle}`}>{selectedDay}</p>
            { isToggle ? 
                <img src={upIcon} alt="up arrow" onClick={() => setIsToggle(false)} className='hover:cursor-pointer' /> :
                <img src={downIcon} alt="down arrow" onClick={() => setIsToggle(true)} className='hover:cursor-pointer' />  
            }
        </div>
       {isToggle &&
            <ul className={`${actionParStyle} ${dropdownStyle}`}>
                <li className={`${liStyle}`}>Past 7 days</li>
                <li className={`${liStyle}`}>Past 1 week</li>
                <li className={`${liStyle} rounded-b-[7px]`}>Past 1 month</li>
            </ul>
        }
    </div>
  )
}