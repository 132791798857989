import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore"

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCRignsnzH7UoINn5pOFMPCkhzwvzY06pA",
    authDomain: "tracker-base-d0e89.firebaseapp.com",
    projectId: "tracker-base-d0e89",
    storageBucket: "tracker-base-d0e89.appspot.com",
    messagingSenderId: "421979467951",
    appId: "1:421979467951:web:39c6069cbb08a87591245a"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
