import React, { ReactNode, useState } from 'react'
import { AppLayout } from '../../components/layouts'
import { getDateFormat, truncateText, } from '../../components/custom-hooks';

type Props = {}

const data = [
  { id: 0, title: 'Indeed', body: 'Clean architecture in software is a very deep topic and there are a lot of opinions and discussions about how to accomplish it and what is the right', time: new Date()},
  { id: 1, title: 'Opay',  body: 'Clean architecture in software is a very deep topic and there are a lot of opinions and discussions about how to accomplish it and what is the right', time: new Date()},
  { id: 2, title: 'FairMoney Alert', body: 'Clean architecture in software is a very deep topic and there are a lot of opinions and discussions about how to accomplish it and what is the right', time: new Date()},
  { id: 3, title: 'FairMoney Alert', body: 'Clean architecture in software is a very deep topic and there are a lot of opinions and discussions about how to accomplish it and what is the right', time: new Date()},
  { id: 4, title: 'contact', body: 'Clean architecture in software is a very deep topic and there are a lot of opinions and discussions about how to accomplish it and what is the right', time: new Date()},
  { id: 5, title: 'LinkedIn', body: 'Clean architecture in software is a very deep topic and there are a lot of opinions and discussions about how to accomplish it and what is the right', time: new Date()},
]

export default function Emergency({}: Props) {
  const [ message, setMessage ] = useState<null | { id: number, title: string, body: string, time: any}>(null);
  const [ layoutToggle, setLayoutToggle ] = useState(false);

  const headerStyle = `text-[19px] mb-2 leading-[46px] tracking-[0.7px] text-ct-primary font-[600]`;
  const containerStyle = 'px-6 pt-3 grid gap-x-5 lg:grid-cols-10 grid-cols-1';
  const msgItemStyle = `w-full bg-white pt-10 rounded-[7px] mb-1 `;

  return (
    <AppLayout>
      <div className={containerStyle}>
        <div className={`${layoutToggle ? 'lg:col-span-6' :'lg:col-span-10'} col-span-1 h-[88vh] overflow-y-auto`}>
          <h1 className={`${headerStyle}`}>Emergency</h1>

          <div className={msgItemStyle} >
            {data.map((item) => (
              <div 
              className={`${layoutToggle ? 'px-3' : 'px-10'}  py-3 border-t grid lg:grid-cols-7 gap-y-1 border-t-gray-100 hover:cursor-pointer`}
              key={item.id} 
              onClick={() => {
                setLayoutToggle(true)
                setMessage(item)
              }}>
                <p className='lg:col-span-1 font-[600] text-[13px]'>{truncateText(item.title, 20)}</p>
                <p className='lg:col-span-5 text-[12px]'>{truncateText(item.body, 120)}</p>
                <p className='lg:col-span-1 text-[12px] lg:text-center'>{getDateFormat(item.time)}</p>
              </div>
            ))}
          </div>
        </div>
        <div className={`${layoutToggle ? 'block' : 'hidden'} lg:col-span-4 col-span-1 h-[88vh] overflow-y-auto`}>
          <div className='lg:mt-[3.4rem] bg-white h-fit rounded-[7px] px-4 py-[2rem]'>
            <div className='w-full flex items-center justify-between mb-4'>
              <h2 className='text-[18px] font-[700] text-ct-primary tracking-wide'>{message?.title}</h2>
              <p className='text-[14px]'>{getDateFormat(message?.time)}</p>
            </div>
            <h2 className='text-[14px]'>{message?.body}</h2>
            {/* <p>{message?.time}</p> */}
          </div>
        </div>
      </div>
    </AppLayout>
  )
}