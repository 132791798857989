import React, {useEffect, useState} from 'react';
import {DaysSelect} from '../../components/ui';
import LineChart from '../../components/ui/LineChart';
import {Link} from 'react-router-dom';
import LiveTrackCard from '../../components/ui/LiveTrackCard';
import {collection, getDocs} from "@firebase/firestore";
import {db} from "../../firebase-config";
import Map from "./Map";

type Props = {}

interface dash {
    cars: string,
    tracking: string,
    liveCars: string,
    offline: string,
}

const liveTracks1 = [0, 1, 2];
const liveTracks2 = [0, 1, 2, 3, 4, 5, 6];

export default function Dashboard({}: Props) {
    const [tab, setTab] = useState(0)
    const [dash, setDash] = useState<dash>({cars: "0", liveCars: "0", offline: "0", tracking: "0"})
    const [dashCars, setDashCars] = useState([])
    const [dashTracker, setDashTracker] = useState([])

    const [focusData, setFocusData] = useState(null)

    const _headerStyle = `text-[19px] leading-[46px] tracking-[0.7px] text-ct-primary font-[600]`;
    const boxStyle = `bg-white rounded-[7px] px-[10px] pt-[7px] pb-[25px]`;
    const boxHeaderStyle = `w-full flex justify-between`;
    const headerParStyle = `text-[14px] leading-[30px] tracking-[0.7px] text-[#282828] font-[800]`;
    const countParStyle = `text-[22px] leading-[20px] tracking-[0.7px] text-[#282828] font-[800]`;
    const actionHeaderStyle = `text-[18px] leading-[46px] tracking-[0.7px] text-ct-primary font-[600]`;
    const btnStyle = `bg-white py-2 text-[#282828] text-[13px] font-[400] tracking-[0.7px]`;
    const headerStyle = `text-[19px] mb-2 leading-[46px] tracking-[0.7px] text-ct-primary font-[600]`;
    const containerStyle = 'px-6 pt-3 grid gap-x-5 lg:grid-cols-10 grid-cols-1';


    //driver model
    const driverModel = collection(db, "drivers")
    const trackerModel = collection(db, "devices")

    const fetchDash = async () => {
        try {
            const dataCars = await getDocs(driverModel)
            // @ts-ignore
            setDashCars(dataCars.docs.reverse())
            const dataTracker = await getDocs(trackerModel)
            // @ts-ignore
            setDashTracker(dataTracker.docs.reverse())
            // @ts-ignore
            setDash({...dash, cars: dataCars.size, liveCars: dataCars.size, tracking: dataTracker.size})
        } catch (error) {
            console.error('Error fetching cars:', error);
        }
    };

    useEffect(() => {
        fetchDash().then(null)
    }, []);

    return (
        <div className={containerStyle}>
            <div className='lg:col-span-6 col-span-1 h-[88vh] overflow-y-auto maps'>
                <h1 className={`${headerStyle}`}>Home</h1>
                <Map focusData={focusData}/>
            </div>
            <div className='lg:col-span-4 col-span-1 h-[88vh] overflow-y-auto dashboard-box'>
                <div className='mt-[3.5rem]'>
                    {/* dashbaord  */}
                    <div className='grid gap-x-5 grid-cols-1 md:grid-cols-2'>
                        <div className="col-span-1 flex items-center h-fit py-1">
                            <h1 className={`${headerStyle}`}>Dashboard</h1>
                        </div>
                        <div className="col-span-1 h-fit">
                            <DaysSelect
                                selectDisplayStyle='py-4 rounded-[7px] pl-[20px] pr-[10px]'
                            />
                        </div>
                    </div>

                    {/* charts 1  */}
                    <div className='grid gap-5 lg:grid-cols-2 grid-cols-1 mt-5'>
                        {/********** cars chart  **********/}
                        <div className={`${boxStyle}`}>
                            <div className={`${boxHeaderStyle}`}>
                                <div className='grid'>
                                    <p className={`${headerParStyle}`}>Cars</p>
                                    <h3 className={`${countParStyle}`}>{dash.cars}</h3>
                                </div>
                                <div
                                    className={`flex gap-x-1 leading-[30px] tracking-[0.7px] text-[#282828] font-[800] text-[12px]`}>
                                    <p>&uarr;</p>
                                    <p>8%</p>
                                </div>
                            </div>
                            <div className='w-full mt-2'>
                                <LineChart
                                    data={[10, 30, 15, 48, 62, 98, 90]}
                                    label={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
                                    color='#EEFAFD'
                                    description='cars'
                                    borderColor='#57CAEB'
                                />
                            </div>
                        </div>
                        {/********* tracking chart  ***********/}
                        <div className={`${boxStyle}`}>
                            <div className={`${boxHeaderStyle}`}>
                                <div className='grid'>
                                    <p className={`${headerParStyle}`}>Tracking</p>
                                    <h3 className={`${countParStyle}`}>{dash.tracking}</h3>
                                </div>
                                <div
                                    className={`flex gap-x-1 leading-[30px] tracking-[0.7px] text-[#282828] font-[800] text-[12px]`}>
                                    <p>&uarr;</p>
                                    <p>8%</p>
                                </div>
                            </div>
                            <div className='w-full mt-2'>
                                <LineChart
                                    data={[10, 30, 45, 48, 62, 76, 90]}
                                    label={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
                                    color='#FFF2F1'
                                    description='tracking'
                                    borderColor='#FA7976'
                                />
                            </div>
                        </div>
                    </div>

                    {/* charts 2  */}
                    <div className='grid gap-5 lg:grid-cols-2 grid-cols-1 mt-5'>
                        {/************  live cars  **************/}
                        <div className={`${boxStyle}`}>
                            <div className={`${boxHeaderStyle}`}>
                                <div className='grid'>
                                    <p className={`${headerParStyle}`}>Live Cars</p>
                                    <h3 className={`${countParStyle}`}>{dash.liveCars}</h3>
                                </div>
                                <div
                                    className={`flex gap-x-1 leading-[30px] tracking-[0.7px] text-[#282828] font-[800] text-[12px]`}>
                                    <p>&uarr;</p>
                                    <p>8%</p>
                                </div>
                            </div>
                            <div className='w-full mt-2'>
                                <LineChart
                                    data={[10, 30, 45, 48, 62, 76, 90]}
                                    label={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
                                    color='#F0E8F9'
                                    description='live cars'
                                    borderColor='#6418C3'
                                />
                            </div>
                        </div>
                        {/************ offline  **************/}
                        <div className={`${boxStyle}`}>
                            <div className={`${boxHeaderStyle}`}>
                                <div className='grid'>
                                    <p className={`${headerParStyle}`}>Offline</p>
                                    <h3 className={`${countParStyle}`}>{dash.offline}</h3>
                                </div>
                                <div
                                    className={`flex gap-x-1 leading-[30px] tracking-[0.7px] text-[#282828] font-[800] text-[12px]`}>
                                    <p>&uarr;</p>
                                    <p>8%</p>
                                </div>
                            </div>
                            <div className='w-full mt-2'>
                                <LineChart
                                    data={[10, 30, 45, 48, 62, 76, 90]}
                                    label={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
                                    description='offline'
                                    color='#EFFBF8'
                                    borderColor='#5DDAB4'
                                />
                            </div>
                        </div>
                    </div>

                    {/* recent live  */}
                    <div className={`bg-white rounded-[7px] pt-[7px] mt-5 h-[50vh] `}>
                        <div className="w-full px-[20px] flex items-center justify-between">
                            <h3 className={`${actionHeaderStyle}`}>Recent Added</h3>
                            <Link to="/cars"
                                  className="text-[12px] text-[#282828] leading-[46px] font-[600] tracking-[0.7px]">See
                                All</Link>
                        </div>
                        <div>
                            <div className='p-4 h-[37vh] overflow-y-auto recent-box'>
                                {
                                    (dashCars.length > 0 ? (
                                        dashCars.map((itemRef, index) => {
                                                // @ts-ignore
                                                const item = itemRef.data()
                                                // @ts-ignore
                                                return <LiveTrackCard key={index} item={item} onTrack={() => {
                                                    ///alert("Hello")
                                                    //set for tracking
                                                    setFocusData(item)
                                                }}/>
                                            }
                                        )
                                    ) : <div>No recent activity</div>)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
