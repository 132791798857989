import React, { ReactNode } from "react"
import logoIcon from '../../../assets/images/logoNoText.png';

interface Props {
    children: ReactNode,
    headerText: string,
    subText: string
}

export default function AuthLayout({ children, headerText, subText }: Props) {
    return (
        <div className='grid grid-cols-3 h-[100vh]'>
            <div className="lg:col-span-1 col-span-3">
                {children}
            </div>

            <div className="relative lg:col-span-2 lg:block hidden bg-ct-primaryLess sign_page">
                <div className='absolute h-full w-full z-10 bg-black/[.7]'>
                    <img src={logoIcon} alt="logo" className="h-[300px] w-fit mx-auto mt-[8rem] animate-pulse" />
                    <div className="w-[75%] mx-auto  mt-[4rem]">
                        <h1 className='text-center text-[35px] font-[PoppinsBold] font-[700] text-[#FF2600]'>{headerText}</h1>
                        <blockquote className='text-center text-white text-[20px] mt-[1rem]'>{subText}</blockquote>
                    </div>
                </div>
            </div>
        </div>
    )
}