import React, { useState } from 'react'
// images
import bellIcon from '../../../assets/icons/bell.svg';
import logoIcon from '../../../assets/images/logo.png';
import avatarImg from '../../../assets/images/user.png';
import hamburgerIcon from '../../../assets/icons/hamburger.svg';
import { Link, useNavigate } from 'react-router-dom';

type Props = {}

export default function Nav() {
    const [ dropdownToggle, setDropdownToggle ] = useState(false);
    const navigate = useNavigate();

    const navStyle = `w-full bg-white h-[70px] flex items-center justify-between fixed top-0 lg:pr-[100px] lg:pl-[30px] md:px-[50px] px-[30px] shadow`
    const textStyle = `text-[14px] leading-[46px]`;

    const handleLogout = () => {
        setDropdownToggle(false);
        navigate('http://45.90.121.21:8082/login');
    }


  return (
    <nav className={`${navStyle}`}>
        <div className='flex gap-x-8 items-center'>
            {/* <img src={hamburgerIcon} alt="collapsible" className='h-[30px] w-[30px] hover:cursor-pointer' /> */}
            <div className='flex gap-2 items-center'>
                <Link to="/"><img src={logoIcon} alt="logo" className='h-[50px]' /></Link>
            </div>
        </div>
        <div className='relative flex items-center gap-x-3'>
            <img src={bellIcon} alt="notification" />
            <img src={avatarImg} alt="avatar" className='h-[35px] w-[35px] rounded-full' />
            <div className='hover:cursor-pointer' onClick={() => setDropdownToggle(prevState => !prevState)}>
                <p className='text-[14px] leading-[21px] tracking-[0.7px] p-0 font-bold'>Admin</p>
                <p className='text-[12px] leading-[18px] tracking-[0.6px] text-ct-primaryLess1'>Admin</p>
            </div>

            {dropdownToggle &&
            <div
                onClick={handleLogout}
                className="absolute top-[3.45rem] -right-3 bg-white text-[13px] px-[30px] py-2 rounded-b-[7px] shadow hover:bg-gray-100 hover:cursor-pointer">
                Logout
            </div>
            }
        </div>
    </nav>
  )
}
