const nav = [
    {
        group_name: null,
        children: [{
        name: 'dashboard',
        route: '/home',
        icon: 'house.svg'
        }]
    },
    {
        group_name: null,
        children: [
            // {
            //     name: 'drivers',
            //     route: '/drivers',
            //     icon: 'drivers.svg'
            // },
            {
                name: 'cars / driver',
                route: '/cars',
                icon: 'car.svg'
            },
            // {
            //     name: 'mobile tracker',
            //     route: '/mobile',
            //     icon: 'mobile.svg'
            // },
            // {
            //     name: 'emergency',
            //     route: '/emergency',
            //     icon: 'emergency.svg'
            // },
        ]
    },
    // {
    //     group_name: 'settings',
    //     children: [
    //         {
    //             name: 'users',
    //             route: '/users',
    //             icon: 'user.svg'
    //         },
    //         {
    //             name: 'backup',
    //             route: '/backup',
    //             icon: 'database.svg'
    //         },
    //         {
    //             name: 'privacy',
    //             route: '/privacy',
    //             icon: 'lock.svg'
    //         },
    //     ]
    // },
]














export {
    nav
}
