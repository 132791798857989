import React, { InputHTMLAttributes } from 'react';

interface Props extends InputHTMLAttributes<HTMLInputElement> {}

export default function CustomInput({ type, placeholder, onChange, required, pattern, maxLength, name, disabled, value }: Props){
    const inputFieldStyle = `w-full rounded-[7px] text-[14px] leading-[26px] font-[400] text-[#576877] border px-[15px] py-[12px] outline-none active:outline-none`;

    return (
        <input
            type={type}
            placeholder={placeholder}
            className={`${inputFieldStyle}`}
            onChange={onChange}
            pattern={pattern}
            required={required}
            maxLength={maxLength}
            name={name}
            disabled={disabled}
            value={value}
        />
    )
}
