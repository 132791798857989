import React from 'react';
import jumbotronImg from '../../assets/images/jumbotron-img.svg';
import shape1 from '../../assets/images/shape1.png';
import shape4 from '../../assets/images/shape4.png';
import { Link } from 'react-router-dom';

const styles = {
    container: 'mt-[90px] lg:h-[90vh] h-[50vh] w-full grid lg:grid-cols-2 grid-cols-1 relative',
    h3: 'text-ct-primary font-[600] text-[14px]',
    h1: 'lg:text-[60px] font-[PoppinsBold] text-[35px] text-[#444] font-[900] lg:leading-[80px] leading-[50px] mt-8',
    left: 'w-[75%] h-[55%] m-auto',
    p: 'text-[17px] text-ct-textGrey leading-[30px] font-[400] mt-5 relative z-10',
    btn: 'text-[13px] py-4 px-10 rounded-[7px] text-white bg-ct-primary shadow tracking-wider hover:bg-ct-primary/[.9]',
    absolute: 'absolute'
}

type Props = {}

export default function Jumbotron({}: Props) {
  return (
    <section id="index" className={styles.container}>
        <div className={styles.left}>
            <h3 className={styles.h3}>Track With Confidence</h3>
            <h1 className={styles.h1}>Car Tracking & Security System</h1>
            <p className={styles.p}>Experience the ultimate in vehicle tracking reliability and security with our advanced tracking solutions for monitoring your vehicles with precision.
                 {/* customizable alerts and detailed reports, you can stay informed about your vehicles' status and performance, empowering you to make informed decisions and take prompt action when needed.  */}
            </p>
            <div className='mt-10 flex relative z-10'>
                <Link to="http://45.90.121.21:8082/login" className={styles.btn}>Get Started &rarr;</Link>
            </div>
            <img src={shape4} alt="" className={`${styles.absolute} top-0 left-0`} />
            <img src={shape1} alt="" className={`${styles.absolute} top-[7rem] right-[51%] lg:block hidden`} />
        </div>
        <div className='w-full' data-aos="fade-up">
            <img src={jumbotronImg} alt="img" className='w-fit aspect-auto h-full object-cover relative z-10 lg:block hidden' />
            <img src={shape1} alt="" className={`${styles.absolute} bottom-[6rem] right-10 lg:block hidden`} />
        </div>
    </section>
  )
}