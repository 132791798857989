import React, {useEffect, useState} from 'react';
import {CustomInput} from '../../components/ui';
import {useNavigate} from 'react-router-dom';
import {AuthLayout} from '../../components/layouts';
import {collection, getDocs, query, where, and} from "@firebase/firestore";
import {db} from "../../firebase-config";

const initialValue = {
    email: '',
    password: ''
}

export default function SignIn() {
    const [formData, setFormData] = useState(initialValue);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const formStyle = `h-fit  overflow-y-auto px-[3rem] pb-[3rem] pt-[2rem] mt-[10rem] grid gap-y-7`;
    // const h1Style = 'text-[20px] font-[600] leading-[42px] text-center';
    const labelStyle = 'text-[13px] font-[400]';
    const btnStyle = 'w-full mt-4 rounded-[7px] text-[14px] text-center bg-ct-primary font-[400] text-white border border-ct-primary px-[15px] py-[12px] outline-none active:outline-none';

    //admin model
    const adminModel = collection(db, "admins")

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        const {email, password} = formData;
        if (email === '' || password === '') return;
        //set is loading
        setLoading(true)
        //do login
        const foundUser = query(adminModel, and(where("email", "==", email), where("pass", "==", password)))
        const userData = await getDocs(foundUser)
        const isLogin = userData.docs[0]?.data()
        //check if found
        if (isLogin) {
            setTimeout(() => {
                //keep local
                navigate('/home');
            }, 2000)
        } else {
            //not founds
            alert("Invalid Credentials")
            setLoading(false)
        }
    }

    useEffect(() => {
        //mainLoader().then(r => r)
    }, []);

    return (
        <AuthLayout
            headerText='Country Side Tracking Services'
            subText='Seamless monitoring and control over your vehicles or mobile devices with our secure login-enabled tracking software.'>
            <form className={formStyle} onSubmit={handleSubmit}>
                <div>
                    <label className={labelStyle} htmlFor="email">Email</label>
                    <CustomInput
                        type='email'
                        required
                        placeholder='exampl@example.com'
                        id='email'
                        onChange={(e: any) => setFormData({...formData, email: e.target.value})}
                    />
                </div>
                <div>
                    <label className={labelStyle} htmlFor="password">Password</label>
                    <CustomInput
                        type='password'
                        required
                        placeholder='************'
                        id='password'
                        onChange={(e: any) => setFormData({...formData, password: e.target.value})}
                    />
                </div>

                <button disabled={loading} className={btnStyle}>{loading ? 'Processing...' : 'Log In'}</button>
            </form>
        </AuthLayout>
    )
}
