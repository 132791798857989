import React, { useState } from 'react'
import { AppLayout } from '../../components/layouts'
import { CustomModal, Empty } from '../../components/ui';
import NewDevice from './NewDevice';

interface DeviceProps {
  fullName: string;
  phone: string;
  homeAddress: string;
  deviceMake: string;
  trackingId: string;
  emergencyContact: string;
}

const devices: DeviceProps[] | [] = [
  // {
  //     fullName: 'okafor henry',
  //     phone: '08128224769',
  //     homeAddress: 'opposite berith hospital',
  //     deviceMake: 'xiaomi, redmi 11',
  //     trackingId: '76723r7qerh',
  //     emergencyContact: '07042744334',
  // }
]

type Props = {}

export default function Devices({}: Props) {
  const [ deviceModal, setDeviceModal ] = useState(false);

  const headerStyle = `text-[19px] mb-2 leading-[46px] tracking-[0.7px] text-ct-primary font-[600]`;
  const containerStyle = 'px-6 pt-5';
  const btnStyle = 'py-2.5 px-5 rounded-[7px] bg-ct-primary text-white hover:font-[500] tracking-[0.7px] text-[13px]';
  const rowContainer = 'w-full grid grid-cols-7 lg:px-7 px-3';
  const rowItem = 'col-span-1';


  return (
    <AppLayout>
      <div className={containerStyle}>
          <div className='w-full flex items-center justify-between'>
            <h1 className={`${headerStyle}`}>Mobile Tracker</h1>
            <button className={btnStyle} onClick={() => setDeviceModal(true)}>New Device</button>
          </div>
          <div className='h-[78vh] overflow-y-auto bg-white mt-3 rounded-[7px]'>
            {devices.length === 0 && <Empty text='No device has been added' style='lg:mt-[10rem] mt-[5rem]' />}
            {devices.length > 0 && 
              <div>
                {/* list header  */}
                <div className={`${rowContainer} py-4 bg-gray-100 rounded-t-[7px] text-[14px] font-[600]`}>
                    <p className={rowItem}>Full Name</p>
                    <p className={rowItem}>Phone</p>
                    <p className={`col-span-2`}>Home Address</p>
                    <p className={rowItem}>Device Make</p>
                    <p className={rowItem}>Tracking ID</p>
                    <p className={rowItem}>Emergency Contact</p>
                </div>
                {/* list body  */}
                <ul className='h-[70vh] overflow-y-auto'>
                    {devices.map((car) => (
                        <li className={`${rowContainer} py-3 border-y border-y-gray-200 text-[13px]`}>
                            <p className={rowItem}>{car.fullName}</p>
                            <p className={rowItem}>{car.phone}</p>
                            <p className={`col-span-2`}>{car.homeAddress}</p>
                            <p className={rowItem}>{car.deviceMake}</p>
                            <p className={rowItem}>{car.trackingId}</p>
                            <p className={`${rowItem} text-center`}>{car.emergencyContact}</p>
                        </li>
                    ))}
                </ul>
              </div>
            }
          </div>
      </div>

      { deviceModal && 
        <CustomModal
          closeModal={() => setDeviceModal(false)}
          modalStyle='lg:w-[35%] md:w-[70%] w-[95%] bg-white mx-auto lg:mt-[2rem] mt-[3rem] rounded-[7px]'
          >
          <NewDevice />
        </CustomModal>
      }
    </AppLayout>
  )
}