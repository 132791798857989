import React from 'react';
import { MdOutlineMyLocation } from "react-icons/md";
import { TbReport } from "react-icons/tb";
import { GrMapLocation } from "react-icons/gr";
import { MdOutlineEditNotifications } from "react-icons/md";

const styles = {
    container: 'w-full pb-[5rem] relative pt-[1rem]',
    flexContainer: 'lg:w-[90%] w-full mx-auto lg:flex grid gap-y-7 justify-evenly items-center mt-[14rem] lg:mt-0',
    card: 'lg:w-[22%] w-[80%] h-[250px] mx-auto rounded-[16px] bg-white box-shadow p-[30px]',
    h2: 'text-center text-[18px] font-[800] mt-3',
    p: 'text-[13px] text-ct-textGrey font-[300] leading-[25px] mt-3 text-center'
}

type Props = {}

export default function FeatureSection({}: Props) {
  return (
    <section id="features" className={styles.container}>
        <div className={styles.flexContainer}>
            <div className={styles.card}>
                <MdOutlineMyLocation size={35} className='mx-auto text-[#FF2600]' />
                <h2 className={styles.h2}>Real-time Tracking</h2>
                <p className={styles.p}>Keep constant tabs on your fleet's location and status in real-time, ensuring optimal efficiency and security.</p>
            </div>
            <div className={styles.card}>
                <TbReport size={35} className='mx-auto text-[#FF2600]' />
                <h2 className={styles.h2}>Detailed Reports</h2>
                <p className={styles.p}>Gain insights into your fleet's performance with comprehensive reports on mileage, fuel consumption, and more.</p>
            </div>
            <div className={styles.card}>
                <GrMapLocation size={35} className='mx-auto text-[#FF2600]' />
                <h2 className={styles.h2}>Geo-Fencing</h2>
                <p className={styles.p}>Set virtual boundaries and receive alerts when vehicles enter or exit designated areas, enhancing security and control.</p>
            </div>
            <div className={styles.card}>
                <MdOutlineEditNotifications size={35} className='mx-auto text-[#FF2600]' />
                <h2 className={styles.h2}>Customizable Alerts</h2>
                <p className={styles.p}>Receive instant alerts for unauthorized vehicle usage, speeding, and other critical events, ensuring prompt action.</p>
            </div>
        </div>
    </section>
  )
}