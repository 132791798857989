import React from 'react';
import calendarIcon from '../../assets/icons/calendar.svg';
import phoneIcon from '../../assets/icons/phone.svg';
import userImg from '../../assets/images/user1.png';

// @ts-ignore
export default function LiveTrackCard({item, onTrack}) {
    const pStyle = `text-[12px] tracking-[0.7px] leading-[20px]`;
    const btnStyle = `text-white bg-ct-green border-0 rounded-[5px] w-[64px] text-center text-[12px] tracking-[0.7px]`;

    return (
        <div className='rounded-[8px] border border-[#C2C2C2] mb-4 p-5'>
            <div className='w-full flex justify-between items-start'>
                <div>
                    <p className={`${pStyle} text-[#757575] font-[400]`}>{item.trackingId}</p>
                    <p className={`${pStyle} text-[#282828] font-[800] py-1`}>{item.carBrand}/{item.carYear}</p>
                    <p className={`${pStyle} text-[#282828] font-[400]`}>IMEI: {item.imei}</p>
                </div>
                <div className='w-fit'>
                    <button onClick={()=>{
                        onTrack()
                    }} className={`${btnStyle}`}>Track</button>
                </div>
            </div>
            <div className="flex flex-wrap justify-between gap-y-2 mt-3">
                <div className="flex gap-x-2 items-center">
                    <img src={userImg} alt="" className='w-[20px] h-[20px] rounded-full'/>
                    <p className={`${pStyle} text-[#282828] font-[400]`}>{item.fullName}</p>
                </div>
                <div className="flex gap-x-2 items-center">
                    <img src={phoneIcon} alt=""/>
                    <p className={`${pStyle} text-[#282828] font-[400]`}>{item.phone}</p>
                </div>
                <div className="flex gap-x-2 items-center">
                    <p className={`${pStyle} text-[#282828] font-[400]`}>KEY: {item.accessCode}</p>
                </div>
            </div>
        </div>
    )
}
