import { ReactNode } from "react";
import Nav from "./Nav";
import Aside from "./Aside";

interface AppLayoutProps {
    children: ReactNode,
}

export default function AppLayout({ children }: AppLayoutProps){
    return (
        <section>
            <Nav />
            <main className="w-full h-[91vh] grid grid-cols-12 mt-[70px]">
                <div className=" col-span-2  px-3.5">
                    <Aside />
                </div>
                <section className="col-span-10 bg-ct-primaryLess">
                { children }
                </section>
            </main>
        </section>
    )
}