import { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import {
    Backup,
    Devices,
    // Drivers,
    Home,
    Privacy,
    Emergency,
    Users,
    Cars,
    SignIn
} from "../pages";
import LandingPage from "../pages/landing-page";

interface RouteProps {
    path: string;
    exact?: boolean;
    element: ReactNode;
}

const routes: RouteProps[] = [
    { path: '/', element: <LandingPage /> },
    { path: '/signin', element: <SignIn /> },
    { path: '/home', element: <Home /> },
    // operational 
    { path: '/cars', element: <Cars />  },
    { path: '/mobile', element: <Devices /> },
    { path: '/emergency', element: <Emergency /> },
    // settings 
    // { path: '/drivers', element: <Drivers /> },
    // { path: '/users', element: <Users /> },
    // { path: '/backup', element: <Backup /> },
    // { path: '/privacy', element: <Privacy /> }
]


export default routes