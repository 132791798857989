import React from 'react';
import logoIcon from '../../assets/images/logo.png';
import { Link } from 'react-router-dom';

const styles = {
    container: 'w-full h-[90px] flex items-center justify-between lg:px-[70px] px-[30px] bg-white fixed top-0 z-30 ',
    logo: {
        container: 'flex gap-2 items-center',
        p: 'hidden lg:block text-[14px] leading-[46px] font-bold text-ct-primary tracking-[0.7px]'
    },
    navlinks: {
        container: 'lg:flex hidden items-center gap-x-[12rem]',
        navItemsContainer: 'flex items-center gap-x-[3rem] text-[14px]',
        btnContainer: 'flex',
        btn: 'text-[13px] py-3 px-5 rounded-[7px] text-white bg-ct-primary'
    }
}

type Props = {}

export default function Nav({}: Props) {
  return (
    <nav className={styles.container}>
        <div className={styles.logo.container}>
            <img src={logoIcon} alt="logo" className='h-[55px]' />
        </div>
        <div className={styles.navlinks.container}>
            <ul className={styles.navlinks.navItemsContainer}>
                <li>
                    <a href="#index" className='hover:text-ct-primary'>Home</a>
                </li>
                <li>
                    <a href="#features" className='hover:text-ct-primary'>Features</a>
                </li>
                <li>
                    <a href="#about" className='hover:text-ct-primary'>About</a>
                </li>
                <li>
                    <a href="#contact" className='hover:text-ct-primary'>Contact</a>
                </li>
            </ul>
            <div className={styles.navlinks.btnContainer}>
                <Link to="http://45.90.121.21:8082/login" className={styles.navlinks.btn}>Get Tracking</Link>
            </div>
        </div>
    </nav>
  )
}