import React from 'react';
import mockupImg from '../../assets/images/mock-img.png';
import shape2 from '../../assets/images/shape2.png';
import shape3 from '../../assets/images/shape3.png';

const styles = {
    container: "w-full pb-[12rem] pt-[3rem] bg-ct-primary/[.05]  relative",
    gridContainer: "grid lg:grid-cols-2 grid-cols-1 relative z-20",
    right: 'w-[80%] mx-auto',
    h2: 'lg:text-[40px] text-[30px] font-[PoppinsBold] font-[700] mt-[4.5rem]',
    p: 'font-[18px] leading-[35px] mt-7 text-black/[.5]'
}

export default function AboutSection() {
  return (
    <section id="about" className={styles.container}>
        <div className={styles.gridContainer}>
            <div className=''>
                <img src={mockupImg} alt='mockup' />
            </div>
            <div className={styles.right}>
                <h2 className={styles.h2}>About Us</h2>
                <p className={styles.p}>
                    At Country Side Tracking Services, we understand the importance of keeping your vehicles safe and secure, 
                    whether they're on the road or parked. With our advanced tracking solutions and years of industry expertise, we empower businesses and 
                    individuals to monitor their vehicles with precision and confidence. 
                    Our dedicated team is committed to delivering reliable and innovative tracking solutions tailored to your unique needs.
                </p>
            </div>
        </div>
        <img src={shape2} alt="" className='transform scale-x-[-1] absolute left-0 bottom-0 z-10'/>
    </section>
  )
}
