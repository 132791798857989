import React from 'react';
import { FaPaperPlane } from "react-icons/fa";


const styles = {
    container: "w-full relative pb-[10rem]",
    innerContainer: "lg:w-[50%] w-[90%] bg-white mx-auto box-shadow px-[3rem] py-[3rem] rounded-[25px] absolute -top-[5rem] z-20 lg:left-[25%] left-[5%]",
    h2: 'text-[30px] font-[PoppinsBold] font-[700] text-center',
    p: 'text-[16px] leading-[25px] mt-1 mb-3 text-ct-textGrey text-center',
    form: 'flex lg:w-[85%] w-full mx-auto mt-4 p-1 border rounded-[10px]',
    input: 'w-full text-[13px] py-3 px-3  outline-0 resize-none ',
    btn: 'bg-ct-primary text-white py-2.5 text-[13px] rounded-r-[10px] px-5 hover:bg-ct-primary/[.9]'
}

export default function Contact() {
  return (
    <section id='contact' className={styles.container}>
        <div className={styles.innerContainer}>
            <h2 className={styles.h2}>Contact Us</h2>
            <p className={styles.p}>Stay informed on our latest technologies and tracking tips!</p>

            <form className={styles.form}>
                <input type="text" placeholder='Your email address' className={styles.input} />
                {/* <textarea name="" id="" rows={5} placeholder='Enter a message' className={styles.input}></textarea> */}
                <button className={styles.btn}><FaPaperPlane /></button>
            </form>
        </div>
    </section>
  )
}
