import React, { useState } from 'react';
import { nav } from '../../../utils/constants';
import { Link, useLocation } from 'react-router-dom';


type Props = {}

export default function Aside({}: Props) {
  const location = useLocation();
  const [imgSrc, setImgSrc] = useState({ name: '', icon: ''});

  const singleNavStyle = `flex items-center gap-x-2 py-2.5 px-5 mb-1 rounded-[5px] w-full hover:bg-ct-primary hover:text-white text-ct-primary hover:font-[500] tracking-[0.7px] text-[14px]`;
  const multiNavStyle = ` flex items-center gap-x-2 py-2.5 px-5  rounded-[5px] hover:bg-ct-primary hover:text-white text-ct-primary hover:font-[500] tracking-[0.7px] text-[14px]`;
  const activeStyle = `bg-ct-primary text-white font-[600]`;
  const navTextStyle = `text-[12px] capitalize`;

  const handleHover = (arg: {name: string, route: string, icon: string}) => {
    const data = { name: arg.name, icon: arg.icon};
    setImgSrc(data);
  };
  const handleHoverOut = () => setImgSrc({ name: '', icon: ''});

  return (
    <aside className='bg-white mt-7'>
        {nav.map((item, index) => (
            <div className='mb-1' key={index}>
            {!item.group_name && (
                item.children.map((sub, index) => {
                    const isTargetRoute = location.pathname.includes(sub.route);
                    return (
                        <Link
                            key={index}
                            to={sub.route}
                            onMouseOut={() => handleHoverOut()}
                            onMouseOver={() => handleHover(sub)}
                            className={`${singleNavStyle} ${isTargetRoute && activeStyle}`}
                            >
                                <img
                                    src={(isTargetRoute || imgSrc?.icon === sub.icon) ?
                                        require(`../../../assets/icons/active.${sub.icon}`) :
                                        require(`../../../assets/icons/${sub.icon}`)
                                    }
                                    alt={sub.name}
                                    />
                                <span className={`${navTextStyle}`}>{sub.name}</span>
                        </Link>
                    )
                })
            )}

            {item.group_name && (
                <div className='mb-10'>
                    <p className={`${navTextStyle} px-4  text-ct-primary font-[600] tracking-[0.7px]`}>{item.group_name}</p>
                    <ul className='ml-[20px] mt-4'>
                        {item.children.map((sub, index) => {
                            const isTargetRoute = location.pathname.includes(sub.route);
                            return(
                                <li key={index} className='mb-1'>
                                    <Link
                                        to={sub.route}
                                        onMouseOut={() => handleHoverOut()}
                                        onMouseOver={() => handleHover(sub)}
                                        className={`${multiNavStyle} ${isTargetRoute && activeStyle}`}
                                        >
                                            <img
                                                src={(isTargetRoute || imgSrc?.icon === sub.icon) ?
                                                    require(`../../../assets/icons/active.${sub.icon}`) :
                                                    require(`../../../assets/icons/${sub.icon}`)
                                                }
                                                alt={sub.name}
                                            />
                                            <span className={`${navTextStyle}`}>{sub.name}</span>
                                    </Link>
                                </li>
                            )
                        })}
                    </ul>

                </div>
            )}
            </div>
        ))}
    </aside>
  )
}
