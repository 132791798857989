import React, {useEffect, useState} from 'react'
import {AppLayout} from '../../components/layouts'
import {CustomModal, Empty} from '../../components/ui';
import NewCar from './NewCar';
import {collection, getDocs, deleteDoc, doc} from "@firebase/firestore";
import {db} from "../../firebase-config";
import { truncateText } from '../../components/custom-hooks';

interface CarProps {
    fullName: string;
    phone: string;
    address: string;
    carYear: string;
    accessCode: string;
    imei: string;
    carBrand: string;
    email: string;
}

type Props = {}


const initialValue: any = {
    accessCode: '',
    fullName: '',
    phone: '',
    address: '',
    email: '',
    carYear: '',
    carBrand: '',
    imei: '',
}

export default function Cars({}: Props) {
    const [carModal, setCarModal] = useState(false);

    const headerStyle = `text-[19px] mb-2 leading-[46px] tracking-[0.7px] text-ct-primary font-[600]`;
    const containerStyle = 'px-6 pt-5';
    const btnStyle = 'py-2.5 px-5 rounded-[7px] bg-ct-primary text-white hover:font-[500] tracking-[0.7px] text-[13px]';
    const rowContainer = 'w-full grid grid-cols-8 lg:px-7 px-3';
    const rowItem = 'col-span-1';

    //driver model
    const driverModel = collection(db, "drivers")

    const [cars, setCars] = useState([]);
    const fetchCars = async () => {
        try {
            const data = await getDocs(driverModel)
            // @ts-ignore
            setCars(data.docs.reverse())
        } catch (error) {
            console.error('Error fetching cars:', error);
        }
    };

    useEffect(() => {
        fetchCars().then(r => null);
    }, []);

    const handleCloseModal = () => {
        fetchCars().then(r => null);
        setCarModal(false);
    };

    const deleteCar = async (ref: any) => {
        await deleteDoc(doc(driverModel, ref.id))
        alert("Deleted and removed")
        fetchCars().then(null)
    }
    // @ts-ignore
    return (
        <AppLayout>
            <div className={containerStyle}>
                <div className='w-full flex items-center justify-between'>
                    <h1 className={`${headerStyle}`}>Car Tracker</h1>
                    <button className={btnStyle} onClick={() => setCarModal(true)}>Assign Car Tracker</button>
                </div>
                <div className='h-[78vh] overflow-y-auto bg-white mt-3 rounded-[7px]'>

                    {cars.length === 0 && <Empty text='No car has been added' style='lg:mt-[10rem] mt-[5rem]'/>}

                    {cars.length > 0 &&
                        <div>
                            {/* list header  */}
                            <div className={`${rowContainer} py-4 bg-gray-100 rounded-t-[7px] text-[14px] font-[600]`}>
                                <p className={rowItem}>Full Name</p>
                                <p className={rowItem}>Phone</p>
                                <p className={`col-span-2`}>Email</p>
                                <p className={rowItem}>Home Address</p>
                                <p className={rowItem}>Car Brand</p>
                                <p className={rowItem}>Access</p>
                                <p className={rowItem}>Action</p>
                            </div>
                            {/* list body  */}
                            <ul className=' overflow-y-auto'>
                                {cars.map((carRef, index) => {
                                    // @ts-ignore
                                    const car = carRef.data()
                                    return (
                                        <li key={index}
                                            className={`${rowContainer} py-3 border-y border-y-gray-200 text-[13px]`}>
                                            <p className={rowItem}>{truncateText(car.fullName, 17)}</p>
                                            <p className={rowItem}>{car.phone}</p>
                                            <p className={'col-span-2'}>{truncateText(car.email, 35)}<br/>[{car.imei}]</p>
                                            <p className={rowItem}>{car.address}</p>
                                            <p className={rowItem}>{car.carBrand + "-" + car.carYear}</p>
                                            <p className={rowItem}>{car.accessCode}</p>
                                            <p className={rowItem}>
                                                <small onClick={() => {
                                                    //remove car
                                                    if (window.confirm("Are you sure to remove car ?")) {
                                                        // @ts-ignore
                                                        deleteCar(carRef).then(r => r)
                                                    }
                                                }} style={{color: 'red', cursor: 'pointer'}}>Del</small>
                                            </p>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    }
                </div>
            </div>

            {carModal &&
                <CustomModal
                    closeModal={() => setCarModal(false)}
                    modalStyle='lg:w-[35%] md:w-[70%] w-[95%] bg-white mx-auto lg:mt-[5rem] mt-[3rem] rounded-[7px]'>
                    <NewCar closeModal={handleCloseModal}/>
                </CustomModal>
            }
        </AppLayout>
    )
}
