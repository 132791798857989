import React, { useState } from 'react'
import { CustomInput } from '../../components/ui';

const initialValue = {
    fullName: '',
    phone: '',
    homeAddress: '',
    emergencyContact: '',
    trackingId: '',
    deviceMake: ''
}

export default function NewDevice() {
    const [ formData, setFormData ] = useState(initialValue);
    const [ loading, setLoading ] = useState(false);

    const formStyle = `lg:h-fit h-[80vh] overflow-y-auto px-7 pb-[3rem] pt-[2rem] grid gap-y-2`;
    const h1Style = 'text-[20px] font-[600] leading-[42px]';
    const labelStyle = 'text-[13px] font-[400]';
    const btnStyle = 'w-full mt-4 rounded-[7px] text-[14px] text-center bg-ct-primary font-[400] text-white border px-[15px] py-[12px] outline-none active:outline-none';

    const handleSubmit = (e: any) => {
        e.preventDefault();
      }    

    return (
        <form className={formStyle}>
            <h1 className={h1Style}>New Device</h1>

            <div>
                <label className={labelStyle} htmlFor="">Full name</label>
                <CustomInput
                    type='text'
                    required
                    placeholder='John Doe'
                />
            </div>
            <div>
                <label className={labelStyle} htmlFor="">Phone</label>
                <CustomInput
                    type='text'
                    required
                    placeholder='+234 810 1122 334'
                    pattern='[0-9]*'
                />
            </div>
            <div>
                <label className={labelStyle} htmlFor="">Device Make</label>
                <CustomInput
                    type='text'
                    required
                    placeholder='Tecno'
                />
            </div>
            <div>
                <label className={labelStyle} htmlFor="">Home address</label>
                <CustomInput
                    type='text'
                    required
                    placeholder='580 Whiff Oaf Lane'
                />
            </div>
            <div>
                <label className={labelStyle} htmlFor="">Emergency contact</label>
                <CustomInput
                    type='tel'
                    required
                    placeholder='+234 810 1122 334'
                    pattern="[0-9]*"
                />
            </div>
            <div>
                <label className={labelStyle} htmlFor="">Tracking ID</label>
                <CustomInput
                    type='text'
                    required
                    placeholder='LP-12345-628-110'
                />
            </div>

            <button onClick={handleSubmit} className={btnStyle}>{loading ? 'Processing...' : 'Add Device'}</button>
        </form>
    )
}
