import React from 'react';
import logoIcon from '../../assets/images/logo.png';
import { MdMailOutline } from "react-icons/md";
import { MdOutlinePhone } from "react-icons/md";
import { Link } from 'react-router-dom';
import shape3 from '../../assets/images/shape3.png';
import { FaFacebook, FaInstagram, FaTwitter, FaWhatsapp } from 'react-icons/fa';


const styles =  {
    container: 'pt-[2rem] relative bottom-0 mt-[8rem] footer-bg',
    copyright: 'text-center w-full relative bottom-0 mt-[5rem] text-[12px] text-white py-3',
    flex: 'lg:flex grid justify-between w-full items-start px-[5rem]',
    a: 'flex items-center gap-x-3',
    ul: 'text-[14px] text-white/[.7] tracking-wide grid gap-y-4 mt-7',
    h3: 'font-[700] text-[18px] text-white'
}

export default function Footer() {
    const date = new Date();

    return (
        <section className={styles.container}>
            <div className={styles.flex}>
                <div>
                    <img src={logoIcon} alt="logo" className='lg:h-[90px] h-[70px]' />
                </div>
                <div className='lg:flex grid lg:gap-x-[15rem] md:gap-x-[7rem] gap-y-10 mt-6'>
                    <div>
                        <h3 className={styles.h3}>Quick Links</h3>
                        <ul className={styles.ul}>
                            <li>
                                <Link to="#index">Home</Link>
                            </li>
                            <li>
                                <Link to="#index">Features</Link>
                            </li>
                            <li>
                                <Link to="#index">Contact</Link>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h3 className={styles.h3}>Contacts</h3>
                        <ul className={styles.ul}>
                            <li>
                                <a className={styles.a} href="mailto:okafohenrie@gmail.com">
                                    <MdMailOutline />
                                    <span>support@csts.com</span>
                                </a>
                            </li>
                            <li>
                                <a className={styles.a} href="tel:01812">
                                    <MdOutlinePhone />
                                    <span>08128224769</span>
                                </a>
                            </li>
                            <li className='flex gap-x-4 items-center mt-3'>
                                <a href='' target='_blank'><FaTwitter size={20} color="#BEC0C9" /></a>
                                <a href='' target='_blank'><FaInstagram size={20} color="#BEC0C9" /></a>
                                <a href='' target='_blank'><FaWhatsapp size={20} color="#BEC0C9" /></a>
                                <a href='' target='_blank'><FaFacebook size={20} color="#BEC0C9" /></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className={styles.copyright}>country-side<sup>&copy;</sup>{date.getFullYear()}</div>

            {/* <img src={shape3} alt="" className="absolute -top-[12rem] left-[3rem] rotate-90" /> */}
        </section>
    )
}
